import React from 'react'
import UpsellPopup from './Upsell'

const Popups = () => (
    <>
        <UpsellPopup />
    </>
)

export default Popups
